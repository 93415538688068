export class AppConsts {
  static readonly urls = {
    login: 'auth/login',
    signup: 'auth/signup',
    signupDetail: 'detail',
    emailVerification: 'auth/email-verification',
    emailVerified: 'auth/account-verified',
    dashboard: 'app/dashboard',
    tokenEndPoint: 'connect/token',
    rooms: 'app/rooms',
    contacts: 'app/contact',
    editUserProfile: 'app/edit-user-profile',
    changeUserPassword: 'app/change-password',
    editPropertySettings: 'app/property-settings',

  };

  static readonly configs = {
    currentUser: 'currentUser',
    currentTenantName: 'currentTenant.name',
    currentTenantId: 'currentTenant.id',
    currentUserName: 'currentUser.name',
    currentUserRoles: 'currentUser.roles',
    currentUserEmail: 'currentUser.email',
    currentUserId: 'currentUser.id'
  };

  static readonly storage = {
    passwordFlow: 'passwordFlow'
  };

  static readonly openIdDict = {
    errors: {
      unConfirmedEmail: 'email_not_confirmed'
    }
  };

  static readonly staticRoleNames = {
    owner: 'Owner',
    family: 'Family',
    guest: 'Guest'
  };

  static appName: 'Property Manual';
}
