import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { AppConsts } from '../AppConsts';
import { clearOAuthStorage } from '@abp/ng.oauth';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from '@abp/ng.core';
import { lastValueFrom } from 'rxjs';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private toastController: ToastController = new ToastController();
  private loadingCtrl: LoadingController = new LoadingController();
  constructor(private router: Router,
    private storage: OAuthStorage,
    private authService: AuthService
  ) {
  }


  async handleError(error) {
    let message = error.message;
    let detail = '';
    if (error.status === 401) {
      await this.logout();
      return;
    }


    try {
      if (error.status === 400 && error?.error) {
        const errorObj = error?.error;
        message = errorObj?.error;
        detail = errorObj?.error_description;
        switch (message) {
          case AppConsts.openIdDict.errors.unConfirmedEmail:
            throw error;
            break;
          default:
            break;
        }

        return;
      }
      if (error.rejection && error.rejection.error) {
        const obj = error.rejection.error;
        message = obj.error ? obj.error : message;
        detail = obj.error_description ? obj.error_description : detail;
      } else if (error?.error?.error) {
        const errorObj = error?.error?.error;
        message = errorObj?.message;
        detail = errorObj?.validationErrors ?? errorObj?.details ?? detail;
      } else {
        message = message ?? 'Internal Error';
        detail = detail ?? '';
      }
    } catch {
      console.log(error);
    } finally {
      this.loadingCtrl.dismiss();
      await this.presentErrorToastWithOptions(message, detail);
    }
  }

  async presentErrorToastWithOptions(message, detail) {
    const toast = await this.toastController.create({
      header: message,
      message: detail,
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'bug-outline',
          text: 'Error',
          handler: () => {
            console.log('Favorite clicked');
          }
        }, {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async logout() {
    clearOAuthStorage(this.storage);
    this.storage.removeItem(AppConsts.storage.passwordFlow);

    await lastValueFrom(this.authService.logout());
    this.router.navigate([AppConsts.urls.login]);
  }
}
